//library
import React, { ReactElement } from 'react';
import { Provider } from 'react-redux';
import Head from 'next/head';
import type { AppProps /*, AppContext */ } from 'next/app';
import { useRouter } from 'next/router';
import { wrapper } from '@components/store/store';
import * as Sentry from '@sentry/node';
import mixpanel from 'mixpanel-browser';

//Amplify Config
import { Amplify } from 'aws-amplify';
import { AMPLIFY_CONFIG } from '@components/lib/config/amplify_config';

Amplify.configure(AMPLIFY_CONFIG, { ssr: true });

//css
import '../styles/tailwind.css';

//code
import useAnalytics from '@components/lib/helper/useAnalytics';
import i18n from '@components/lib/helper/i18n';

//Sentry
/** Follow: https://leerob.io/blog/configuring-sentry-for-nextjs-apps */
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        normalizeDepth: 20
    });
}

if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
    mixpanel.init(
        process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
        {
            debug: process.env.NODE_ENV === 'development',
            track_pageview: true,
            persistence: 'localStorage'
        },
        'mixpanel'
    );
}

/**
 * The app function.
 *
 * @param {AppProps} appProps - The props of the page.
 * @returns {ReactElement} - The page we want to navigate.
 */
function MyApp(appProps: AppProps): ReactElement {
    const { Component, pageProps } = appProps;
    const { store } = wrapper.useWrappedStore(pageProps);

    const router = useRouter();
    useAnalytics(router);
    i18n.changeLanguage(router.locale);

    return (
        <Provider store={store}>
            <Head>
                <title>GetSolar: Simulator</title>
                <link rel="icon" href="/favicon.ico" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <Component {...pageProps} />
        </Provider>
    );
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext: AppContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);

//   return { ...appProps }
// }

export default MyApp;
